const React = require("react");
const createReactClass = require("create-react-class");
const withRouter = require("react-router").withRouter;

const Link = require("react-router-dom").Link;

const parseQuery = require("../../util.js").parseQuery;
import LoginMethod from "../../comps/LoginMethod";
const TeamMode = require("../../comps/TeamMode.js");
const AdsList = require("../../comps/AdsList.js");
const Country = require("../../comps/Country.js");
const api = require("../../api.js");
import imgEmptyProfile from "./empty-profile.jpg";

const limit = 100;

const KNOWN_ROLES = new Set(["admin", "member", "viewer"]);

const UserList = createReactClass({
  getInitialState: function () {
    return {
      loading: false,
      items: [],
    };
  },

  load: function () {
    const param = parseQuery(this.props.history.location.search);

    this.setState({
      loading: true,
    });

    api
      .get("/admin-api/users", null)
      .then(
        (data) => {
          this.setState({
            items: data.items,
          });
        },
        (err) => {
          alert(err.message);
        },
      )
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  },

  componentDidMount: function () {
    this.load();
  },

  onChangeRole: function (newRole, user) {
    if (newRole === "" || newRole === user.role) {
      return;
    }
    if (!confirm(`${user.name}의 권한을 ${newRole}로 변경하시겠습니까?`)) {
      return;
    }
    api
      .put(`/admin-api/users/${user.uid}/role`, {
        role: newRole,
      })
      .then(
        (data) => {
          this.load();
        },
        (err) => {
          alert(err.message);
        },
      );
  },

  onDeleteUser: function (user) {
    if (!confirm(`${user.name}를 삭제하시겠습니까?`)) {
      return;
    }
    api.del(`/admin-api/users/${user.uid}`, null).then(
      (data) => {
        this.load();
      },
      (err) => {
        alert(err.message);
      },
    );
  },

  renderRole: function (user) {
    return (
      <form className="pure-form">
        <select
          value={user.role || ""}
          onChange={(evt) => this.onChangeRole(evt.target.value, user)}
        >
          <option value="" disabled={true}>
            미인증
          </option>
          <option value="admin">admin</option>
          <option value="member">member</option>
          <option value="viewer">viewer</option>
        </select>
      </form>
    );
  },

  renderUser: function (user) {
    const isUnknown = !user["email-verified?"] || !KNOWN_ROLES.has(user.role);
    return (
      <tr
        key={user.uid}
        style={isUnknown ? { backgroundColor: "#ff000040" } : null}
      >
        <td>
          <img src={user.photo || imgEmptyProfile} style={{ width: 30 }} />
          {user.name}
        </td>
        <td>
          {user.email}
          {!user["email-verified?"] && (
            <span style={{ color: "red", marginLeft: 5 }}>(미인증)</span>
          )}
        </td>
        <td>{this.renderRole(user)}</td>
        <td>
          <button
            className="pure-button"
            onClick={(_evt) => this.onDeleteUser(user)}
          >
            삭제
          </button>
        </td>
      </tr>
    );
  },

  render: function () {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        <table className="pure-table pure-table-bordered">
          <thead>
            <tr className="stickyHeader">
              <th>이름</th>
              <th>email</th>
              <th>Role</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{this.state.items.map(this.renderUser)}</tbody>
        </table>
      </div>
    );
  },
});

export default UserList;
