const React = require("react");
const createReactClass = require("create-react-class");
const constants = require("../constants.js");

const badgeStyle = {
  padding: 8,
  borderRadius: 4,
  color: "white",
  textDecoration: "none",
  display: "inline-block",
  whiteSpace: "nowrap",
};

const basicStyle = Object.assign({}, badgeStyle, {
  backgroundColor: "#50a4fa",
});

const unitStyle = Object.assign({}, badgeStyle, { backgroundColor: "#75c7b7" });

const locationStyle = Object.assign({}, badgeStyle, {
  backgroundColor: "#f5a623",
});

const TeamMode = createReactClass({
  render: function () {
    const mode = this.props.mode;

    switch (mode) {
      case constants.teamModeBasic:
        return <span style={basicStyle}>기본</span>;
      case constants.teamModeUnit:
        return <span style={unitStyle}>유닛</span>;
      case constants.teamModeLocation:
        return <span style={locationStyle}>위치</span>;
      default:
        return null;
    }
  },
});

module.exports = TeamMode;
