const React = require("react");
const createReactClass = require("create-react-class");

function tzNameToString(tzName) {
  return tzName || "(unset)";
}

function tzOffsetToString(min) {
  const sign = min < 0 ? "-" : "+";
  const absMin = Math.abs(min);
  const h = Math.floor(absMin / 60);
  const m = absMin % 60;
  const leadingZero = (num) => ("0" + num).slice(-2);
  return `UTC${sign}${leadingZero(h)}:${leadingZero(m)}`;
}

const TzInfo = createReactClass({
  render: function () {
    const twoLine = this.props.twoLine;
    const tzName = this.props.name;
    const offsetMin = this.props.offsetMin || 0;

    return (
      <span>
        {tzNameToString(tzName)}
        {twoLine && <br />}
        <span
          style={{
            fontSize: "0.8em",
            color: "silver",
            marginLeft: twoLine ? 0 : 5,
          }}
        >
          {tzOffsetToString(offsetMin)}
        </span>
      </span>
    );
  },
});

module.exports = TzInfo;
