import firebase from "firebase/app";

function getAuth() {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((idToken) => `Bearer ${idToken}`);
}

export async function get(baseUrl, param) {
  let url = baseUrl;
  if (param != null) {
    const params = new URLSearchParams(param);
    url = url + "?" + params.toString();
  }
  const auth = await getAuth();
  const resp = await fetch(url, {
    method: "GET",
    mode: "same-origin",
    headers: {
      Authorization: auth,
    },
  });
  if (!resp.ok) {
    throw new Error("resp is not ok:" + resp.status + " " + resp.statusText);
  }
  return resp.json();
}

function paramToFormData(param) {
  if (param == null) {
    return null;
  }
  return new URLSearchParams(param).toString();
}

export async function post(url, param) {
  const formData = paramToFormData(param);
  const auth = await getAuth();
  const resp = await fetch(url, {
    method: "POST",
    mode: "same-origin",
    headers: {
      Authorization: auth,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formData,
  });
  if (!resp.ok) {
    throw new Error("resp is not ok:" + resp.status + " " + resp.statusText);
  }
  return resp.json();
}

export async function put(url, param) {
  const formData = paramToFormData(param);
  const auth = await getAuth();
  const resp = await fetch(url, {
    method: "PUT",
    mode: "same-origin",
    headers: {
      Authorization: auth,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formData,
  });
  if (!resp.ok) {
    throw new Error("resp is not ok:" + resp.status + " " + resp.statusText);
  }
  return resp.json();
}

export async function del(url, param) {
  const formData = paramToFormData(param);
  const auth = await getAuth();
  const resp = await fetch(url, {
    method: "DELETE",
    mode: "same-origin",
    headers: {
      Authorization: auth,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formData,
  });
  if (!resp.ok) {
    throw new Error("resp is not ok:" + resp.status + " " + resp.statusText);
  }
  return resp.json();
}
