const React = require("react");
const createReactClass = require("create-react-class");
const withRouter = require("react-router").withRouter;
const Link = require("react-router-dom").Link;
const api = require("../api.js");

const langs = ["en", "ko"];
const LANG_ALL = "all";

function dateToString(date) {
  const t = new Date(date);
  const datePart = `${t.getFullYear()}-${t.getMonth() + 1}-${t.getDate()}`;
  const timePart = t.toLocaleTimeString();
  return `${datePart} ${timePart}`;
}

const NOTICE_ICON_OPTIONS = [
  // 공지: Loudspeaker
  "📢",
  // 이벤트: Party Popper
  "🎉",
  // 점검: Hammer and Wrench
  "🛠️",
];

const IconSelector = ({ value, onChange }) => {
  return (
    <form className="pure-form">
      {NOTICE_ICON_OPTIONS.map((option, idx) => {
        return (
          <label key={idx}>
            <input
              type="radio"
              value={option}
              checked={value === option}
              onChange={(evt) => onChange(evt.target.value)}
            />
            <span style={{ marginLeft: 5, marginRight: 10 }}>{option}</span>
          </label>
        );
      })}
      <input
        id="custom-icon"
        className="pure-input"
        type="text"
        value={value}
        placeholder="Icon"
        onChange={(evt) => onChange(evt.target.value)}
      />
    </form>
  );
};

const DescriptionView = ({ value }) => {
  if (value == null || value === "") {
    return null;
  }
  return (
    <pre
      style={{
        padding: 5,
        fontSize: "0.8em",
        background: "rgb(248, 248, 255)",
      }}
    >
      {value}
    </pre>
  );
};

const NoticeList = createReactClass({
  getInitialState: function () {
    return {
      lang: LANG_ALL,
      editItem: null,
      editIcon: null,
      editTitle: null,
      editDesc: null,
      editUrl: null,
      newIcon: NOTICE_ICON_OPTIONS[0],
      newTitleEn: "",
      newDescEn: "",
      newUrlEn: "",
      newTitleKo: "",
      newDescKo: "",
      newUrlKo: "",
      items: [],
    };
  },

  load: function () {
    api.get("/api/notices", null).then(
      (data) => {
        this.setState({
          items: data.items,
        });
      },
      (err) => {
        alert(err.message);
      },
    );
  },

  selectLang: function (lang) {
    this.setState({
      lang: lang,
    });
  },

  onClickDelete: function (item) {
    if (!confirm("정말 삭제하시겠습니까?\n\r" + item.title)) {
      return;
    }
    api.del(`/api/notices/${item.id}`, {}).then(
      () => {
        this.load();
      },
      (err) => {
        alert(err.message);
      },
    );
  },

  onClickApplyEdit: function (e) {
    // https://github.com/ReactTraining/react-router/issues/1933
    // chrome 이 #/ 앞에 ?를 붙여서 페이지 새로고침이 발생한다.
    e.preventDefault();

    const item = this.state.editItem;
    if (item == null) {
      alert("BUG!");
      return;
    }
    const data = {};
    if (this.state.editIcon != item.icon) {
      data.icon = this.state.editIcon;
    }
    if (this.state.editTitle != item.title) {
      data.title = this.state.editTitle;
    }
    if (this.state.editDesc != item.description) {
      data.description = this.state.editDesc;
    }
    if (this.state.editUrl != item.url) {
      data.url = this.state.editUrl;
    }
    if (Object.keys(data).length === 0) {
      alert("No change!");
      return;
    }
    api.put(`/api/notices/${item.id}`, data).then(
      () => {
        this.load();
      },
      (err) => {
        alert(err.message);
      },
    );
  },

  onClickCancelEdit: function (e) {
    // https://github.com/ReactTraining/react-router/issues/1933
    // chrome 이 #/ 앞에 ?를 붙여서 페이지 새로고침이 발생한다.
    e.preventDefault();

    this.setState({
      editItem: null,
      editIcon: null,
      editTitle: null,
      editDesc: null,
      editUrl: null,
    });
  },

  onClickEdit: function (item) {
    this.setState({
      editItem: item,
      editIcon: item.icon,
      editTitle: item.title,
      editDesc: item.description,
      editUrl: item.url,
    });
  },

  componentDidMount: function () {
    this.load();
  },

  onChangeEditTitle: function (evt) {
    this.setState({
      editTitle: evt.target.value,
    });
  },

  onChangeEditDesc: function (evt) {
    this.setState({
      editDesc: evt.target.value,
    });
  },

  onChangeEditUrl: function (evt) {
    this.setState({
      editUrl: evt.target.value,
    });
  },

  onClickPost: function (evt) {
    // https://github.com/ReactTraining/react-router/issues/1933
    // chrome 이 #/ 앞에 ?를 붙여서 페이지 새로고침이 발생한다.
    evt.preventDefault();

    const noKo = this.state.newTitleKo === "" && this.state.newUrlKo === "";
    const noEn = this.state.newTitleEn === "" && this.state.newUrlEn === "";

    if (noKo && noEn) {
      alert("Fill form first!");
      return;
    }

    if (!noKo) {
      if (this.state.newTitleKo === "") {
        alert("한글 제목이 비었습니다.");
        return;
      }
      if (this.state.newUrlKo === "") {
        alert("한글 url이 비었습니다.");
        return;
      }
    }

    if (!noEn) {
      if (this.state.newTitleEn === "") {
        alert("영어 제목이 비었습니다.");
        return;
      }
      if (this.state.newUrlEn === "") {
        alert("영어 url이 비었습니다.");
        return;
      }
    }

    Promise.resolve()
      .then(() => {
        if (!noKo) {
          return api.post("/api/notices", {
            icon: this.state.newIcon,
            title: this.state.newTitleKo,
            description: this.state.newDescKo,
            url: this.state.newUrlKo,
            lang: "ko",
          });
        }
      })
      .then(() => {
        if (!noEn) {
          return api.post("/api/notices", {
            icon: this.state.newIcon,
            title: this.state.newTitleEn,
            description: this.state.newDescEn,
            url: this.state.newUrlEn,
            lang: "en",
          });
        }
      })
      .then(
        () => {
          let newState = {};
          if (!noKo) {
            newState.newTitleKo = "";
            newState.newDescKo = "";
            newState.newUrlKo = "";
          }
          if (!noEn) {
            newState.newTitleEn = "";
            newState.newDescEn = "";
            newState.newUrlEn = "";
          }
          this.setState(newState);
          this.load();
        },
        (err) => {
          alert(err.message);
          console.error(err);
        },
      );
  },

  renderEdit: function (item) {
    if (this.state.editItem == null) {
      return;
    }
    return (
      <div style={{ marginTop: 15 }}>
        <IconSelector
          value={this.state.editIcon}
          onChange={(value) => this.setState({ editIcon: value })}
        />
        <form className="pure-form pure-form-stacked">
          <fieldset>
            <input
              className="pure-input-1"
              type="text"
              value={this.state.editTitle}
              onChange={this.onChangeEditTitle}
              placeholder="제목"
            />
            <textarea
              className="pure-input-1"
              value={this.state.editDesc || ""}
              onChange={this.onChangeEditDesc}
              placeholder="간략한 설명"
            />
            <input
              className="pure-input-1"
              type="text"
              value={this.state.editUrl}
              onChange={this.onChangeEditUrl}
              placeholder="상세 페이지 url 주소"
            />
            <button
              className="pure-button pure-button-primary"
              onClick={this.onClickApplyEdit}
            >
              완료
            </button>
            <button className="pure-button" onClick={this.onClickCancelEdit}>
              취소
            </button>
          </fieldset>
        </form>
      </div>
    );
  },

  renderRow: function (item, i) {
    if (this.state.lang !== LANG_ALL && this.state.lang !== item.lang) {
      return null;
    }
    const isEditMe = this.state.editItem == item;
    return (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.lang}</td>
        <td>
          <div>
            <div>
              {item.icon} {item.title} [
              <a href={item.url} target="_blank">
                link
              </a>
              ]
            </div>
            <DescriptionView value={item.description} />
          </div>
          {isEditMe && this.renderEdit(item)}
        </td>
        <td>{dateToString(item.created_time)}</td>
        <td>
          <button
            className="pure-button"
            onClick={() => this.onClickEdit(item)}
          >
            수정
          </button>
          <button
            className="pure-button"
            onClick={() => this.onClickDelete(item)}
          >
            삭제
          </button>
        </td>
      </tr>
    );
  },

  renderLangFilter: function () {
    return (
      <select
        value={this.state.lang}
        onChange={(evt) => {
          this.selectLang(evt.target.value);
        }}
      >
        <option value={LANG_ALL}>
          {this.state.lang === LANG_ALL ? "언어" : "all"}
        </option>
        {langs.map((lang) => {
          return (
            <option key={lang} value={lang}>
              {lang}
            </option>
          );
        })}
      </select>
    );
  },

  renderNew: function () {
    return (
      <div>
        <IconSelector
          value={this.state.newIcon}
          onChange={(value) => this.setState({ newIcon: value })}
        />
        <form className="pure-form pure-form-stacked">
          <fieldset className="pure-group">
            <legend>한국어</legend>
            <input
              className="pure-input-1"
              type="text"
              value={this.state.newTitleKo}
              placeholder="제목"
              onChange={(evt) =>
                this.setState({ newTitleKo: evt.target.value })
              }
            />
            <textarea
              className="pure-input-1"
              value={this.state.newDescKo}
              placeholder="간단 요약"
              onChange={(evt) => this.setState({ newDescKo: evt.target.value })}
            />
            <input
              className="pure-input-1"
              type="text"
              value={this.state.newUrlKo}
              placeholder="상세 페이지 url 주소"
              onChange={(evt) => this.setState({ newUrlKo: evt.target.value })}
            />
          </fieldset>
          <fieldset className="pure-group">
            <legend>English</legend>
            <input
              className="pure-input-1"
              type="text"
              style={{ width: "100%" }}
              value={this.state.newTitleEn}
              placeholder="Title"
              onChange={(evt) =>
                this.setState({ newTitleEn: evt.target.value })
              }
            />
            <textarea
              className="pure-input-1"
              value={this.state.newDescEn}
              placeholder="Description"
              onChange={(evt) => this.setState({ newDescEn: evt.target.value })}
            />
            <input
              className="pure-input-1"
              type="text"
              style={{ width: "100%" }}
              value={this.state.newUrlEn}
              placeholder="Detail page URL address"
              onChange={(evt) => this.setState({ newUrlEn: evt.target.value })}
            />
          </fieldset>

          <button
            type="submit"
            className="pure-button pure-button-primary"
            onClick={this.onClickPost}
          >
            Post
          </button>
        </form>
      </div>
    );
  },

  render: function () {
    return (
      <div>
        <div
          style={{
            padding: 15,
            margin: 15,
            background: "#f1f4f9",
            borderRadius: 3,
            border: "1px solid #dfe1e5",
          }}
        >
          {this.renderNew()}
        </div>
        <table className="pure-table pure-table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>{this.renderLangFilter()}</th>
              <th>제목</th>
              <th>생성일</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{this.state.items.map(this.renderRow)}</tbody>
        </table>
      </div>
    );
  },
});

module.exports = withRouter(NoticeList);
