const React = require("react");
const createReactClass = require("create-react-class");
const { countryCodeEmoji } = require("country-code-emoji");

const Country = createReactClass({
  render: function () {
    const code = this.props.code;
    const name = this.props.name;

    if (code == null || code == "") {
      return null;
    }

    return (
      <span>
        {countryCodeEmoji(code)}
        <span style={{ fontSize: "0.8em", color: "silver" }}>{name}</span>
      </span>
    );
  },
});

module.exports = Country;
