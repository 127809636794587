const React = require("react");
const createReactClass = require("create-react-class");

import logoKakao from "./kakao-logo.png";
import logoFb from "./fb-logo.png";
import logoGoogle from "./google-logo.png";
import logoEmail from "./email-logo.png";
import logoApple from "./apple-logo.png";

const imgStyle = {
  width: 24,
  height: 24,
  marginRight: 5,
  verticalAlign: "middle",
};

const LoginMethod = createReactClass({
  render: function () {
    const item = this.props.user;

    if (item.kakao_id != null) {
      return (
        <span>
          <img
            src={logoKakao}
            alt="Kakao"
            style={imgStyle}
            title={item.kakao_id}
          />
          <span>{item.email || "(no email)"}</span>
        </span>
      );
    } else if (item.fb_id != null) {
      return (
        <span>
          <img src={logoFb} alt="FB" style={imgStyle} title={item.fb_id} />
          <span>{item.email || "(no email)"}</span>
        </span>
      );
    } else if (item.google_id != null) {
      return (
        <span>
          <img
            src={logoGoogle}
            alt="Google"
            style={imgStyle}
            title={item.google_id}
          />
          <span>{item.email || "(no email)"}</span>
        </span>
      );
    } else if (item.email_id != null) {
      return (
        <span>
          <img src={logoEmail} alt="E-mail" style={imgStyle} />
          <span>{item.email_id}</span>
        </span>
      );
    } else if (item.apple_id != null) {
      return (
        <span>
          <img src={logoApple} alt="Apple" style={imgStyle} />
          <span>{item.email || "(no email)"}</span>
        </span>
      );
    } else {
      return null;
    }
  },
});

export default LoginMethod;
