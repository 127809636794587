const React = require("react");
const createReactClass = require("create-react-class");

function tzNameToString(tzName) {
  return tzName || "(unset)";
}

function tzOffsetToString(min) {
  const sign = min < 0 ? "-" : "+";
  const absMin = Math.abs(min);
  const h = Math.floor(absMin / 60);
  const m = absMin % 60;
  const leadingZero = (num) => ("0" + num).slice(-2);
  return `UTC${sign}${leadingZero(h)}:${leadingZero(m)}`;
}

const AdsList = createReactClass({
  name: "AdsList",

  renderAdsColumn: function (label, value) {
    if (value == null) {
      return null;
    }
    const adsLabelStyle = {
      fontSize: "0.8em",
      color: "silver",
      marginLeft: 5,
      marginRight: 3,
    };
    return (
      <React.Fragment>
        <span style={adsLabelStyle}>{label}:</span>
        {value}
      </React.Fragment>
    );
  },

  renderAdsRow: function (item, idx) {
    const installTime =
      item.install_time && new Date(item.install_time).toLocaleString();
    return (
      <li key={idx}>
        {item.source}
        {this.renderAdsColumn("campaign", item.campaign)}
        {this.renderAdsColumn("term", item.term)}
        {this.renderAdsColumn("install", installTime)}
      </li>
    );
  },

  render: function () {
    const ads = this.props.ads;

    if (ads == null || ads.length == 0) {
      return null;
    }

    return (
      <ul style={{ paddingInlineStart: 10 }}>{ads.map(this.renderAdsRow)}</ul>
    );
  },
});

module.exports = AdsList;
