const JSZip = require("jszip");
const saveAs = require("file-saver");
const libAsync = require("async");

function sanitizeName(filename) {
  return filename.replaceAll(/[/\\.]/g, "_");
}

function downloadBarcodeImages(items, outputName, onProgress) {
  const zip = new JSZip();
  const allCount = items.length;
  let doneCount = 0;

  const filenameMap = {};
  const genFileName = function (seed) {
    if (seed == "") {
      seed = "untitled";
    }
    seed = sanitizeName(seed);
    if (filenameMap[seed] == null) {
      filenameMap[seed] = 1;
      return seed;
    } else {
      return `${seed}_(${filenameMap[seed]++})`;
    }
  };

  return libAsync
    .eachLimit(items, 5, function (item, callback) {
      const barcode = item.barcode;
      const url = item.photo_url;

      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          let filename = genFileName(barcode);
          const contentType = response.headers.get("content-type");
          switch (contentType) {
            case "image/png":
              filename += ".png";
              break;
            case "image/jpeg":
              filename += ".jpeg";
              break;
            case "image/bmp":
              filename += ".bmp";
              break;
            case "image/gif":
              filename += ".gif";
              break;
            case "image/apng":
              filename += ".apng";
              break;
            case "image/tiff":
              filename += ".tif";
              break;
            case "image/webp":
              filename += ".webp";
              break;
            case "image/x-icon":
              filename += ".ico";
              break;
            case "image/svg+xml":
              filename += ".svg";
              break;
            default:
              console.error("unknown content type!", url, contentType);
              throw new Error(
                "unknown content type! " + contentType + " url: " + url,
              );
          }

          zip.file(filename, response.arrayBuffer(), { binary: true });
          doneCount++;
          onProgress(doneCount, allCount);
        })
        .then(
          function onSuccess() {
            callback(null);
          },
          function onError(err) {
            callback(
              new Error(
                "download failed! barcode:" + barcode + " " + err.message,
              ),
            );
          },
        );
    })
    .then(function onSuccess() {
      console.log("Download done!");
      return zip.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, outputName);
      });
    });
}

module.exports = {
  downloadBarcodeImages: downloadBarcodeImages,
};
