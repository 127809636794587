const React = require("react");
const ReactDOM = require("react-dom");
import firebase from "firebase/app";
import "firebase/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import App from "./app.js";

firebase.initializeApp({
  apiKey: "AIzaSyDaiS3nKEyDYR8ssV1XE4XAkk5fDjMiexo",
  authDomain: "boxhero-admin.firebaseapp.com",
  projectId: "boxhero-admin",
  storageBucket: "boxhero-admin.appspot.com",
  messagingSenderId: "323724344861",
  appId: "1:323724344861:web:5db3a4fb77a31f032490f4",
});

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

function SignInScreen() {
  const [isSignedIn, setIsSignedIn] = React.useState(false); // Local signed-in state.
  const [role, setRole] = React.useState("");
  const [errMsg, setErrMsg] = React.useState("");

  const updateRole = () => {
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        setRole(idTokenResult.claims.bh_role);
      })
      .catch((error) => {
        console.log(error);
        setErrMsg(error.message);
      });
  };

  // Listen to the Firebase Auth state and set the local state.
  React.useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn(!!user);
        if (user != null) {
          updateRole();
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  if (!isSignedIn) {
    return (
      <div>
        <h1>BoxHero Admin</h1>
        <p>Please sign-in:</p>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
    );
  }

  if (role === "member" || role === "admin" || role === "viewer") {
    return <App role={role} />;
  } else {
    const currentUser = firebase.auth().currentUser;
    return (
      <div>
        <h1>Not confirmed by administrators. Go kick and ask them.</h1>
        <ul>
          <li>Uid: {currentUser.uid}</li>
          <li>Name: {currentUser.displayName}</li>
          <li>Email: {currentUser.email}</li>
          <li>
            <img src={currentUser.photoURL} alt="Profile photo" />
          </li>
          <li>
            <button
              className="pure-button"
              onClick={() => {
                firebase
                  .auth()
                  .currentUser.getIdToken(true)
                  .then(() => updateRole());
              }}
            >
              Refresh
            </button>
          </li>
          <li>
            <button
              className="pure-button"
              onClick={() =>
                firebase
                  .auth()
                  .signOut()
                  .then(() => window.location.replace("/"))
              }
            >
              Sign-out
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

ReactDOM.render(<SignInScreen />, document.getElementById("container"));
